


export class BrowserAppEvaluationToolInfo {

  public version = '2024.9.2.2';

  constructor() {
  }

}
